.styled-steps {
  display: flex;
  justify-content: center;
  margin-top: 2.5%;

  transform: translateY(-30px);
  opacity: 0;
}

.progress-bar-container {
  width: 100%;
  height: 0.15em;
}

.progress-bar-step {
  background-color: #173655;
  height: 100%;
  position: relative;
  width: 100%;
  transition: width 0.5s ease-out;
}
