/* Input form Style */
.ph-input {
  font-family: 'Inter', 'Source Sans Pro', sans-serif;
  border: 1px solid #5286B5;
  border-radius: 16px;
  height: 2.6em;
  /*width: 300px;*/
  width: 94%;
  color: #173655;
  font-size: 1em;
  padding-left: 20px;
  box-shadow: none;  
}

/* Label style after Input feild is in focus. Can also use input:focus ~ label to select sibling. */
.ph-input:focus + label, .ph-input:valid + label{
  font-size: 0.65em;
  color: #afbdcf;
  top: -5px;
  left: 10px;
  background: #F7FAFC;
  padding: 0px 5px 0px 5px;
}

.input_wrap {
    width:auto; 
    height:auto;
    position:relative;
    margin-bottom: 0.75em;
}

.checkbox-wrap {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox-wrap label{
  padding-top: 0 !important;
  padding-left: 5px;
  font-size: 0.9em;
}

.checkbox-wrap label a{
  font-style: italic;
}

.checkbox-wrap input{
  accent-color: #5286B5;
}

.input_wrap label {
  font-family: 'Inter', 'Source Sans Pro', sans-serif;
  font-size: 1em;
  color: #173655;
  padding: 1em;
  padding-left: 1.55em;
  position: absolute;
  top: -3px;
  left: 0;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
  pointer-events: none;
}

.ph-input:focus {
    outline:none;
}

#agreedToTerms, #subscribedToNewsletter{
  font-size: 0.8em;
}

.css-1dimb5e-singleValue{
  color: #173655 !important;
  font-family: 'Inter', 'Source Sans Pro', sans-serif;
}