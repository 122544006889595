body {
    font-family: 'Inter', 'Source Sans Pro', sans-serif;
    margin: 0;
    background-color: #F7FAFC;
}

* {
    font-family: 'Inter', 'Source Sans Pro', sans-serif;
}

button{
    font-family: 'Inter', 'Source Sans Pro', sans-serif;
}