.user-data {
    border: 3px solid black;
    flex-direction: column;
    width: fit-content;
    -webkit-box-shadow: 0px 0px 0px 15px rgba(251,251,252,1);
    -moz-box-shadow: 0px 0px 0px 15px rgba(251,251,252,1);
    box-shadow: 0px 0px 0px 15px rgba(251,251,252,1);
}

.error-message-container{
    background-color: #FFCEC6;
    padding: 0.25em 0;
    margin-top: 2em;
    border-radius: 5px;
}

.error-message{
    font-size: 0.8em;
    color: #3B3F3F;
    padding-left: 1em;
    max-width: 90%;
    width: 90%;
}

.reservation-details-container {
    border-radius: 16px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
}

.reservation-details-container div {
    padding: 0.5em;
}

.reservation-details-container div:not(:last-child) { 
    border-bottom: 1px solid #97B6D3;   
}

.reservation-details-container div p{
    padding: 0 0 0 1rem;
    margin: 0;
}

.details-container-desc{
    font-size: 0.7em;
    color: #718096;
}

.details-container-value {
    font-size: 0.9em;
    color: #173655;
}