.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin: auto;
  max-width: 620px;
  padding: 0 5%;
  margin-bottom: 1em;
}

.card {
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  max-width: 18.625rem;
  padding: 1rem;
  text-align: center;
  color: #173655;
}

.card-title {
  margin: 0 0 0.75rem 0;
}

.card-subtitle {
  color: gray;
  margin: 0.5rem 0 0 0;
}

.card-text {
  margin: 0 0 0.25em 0;
}

.card-footer {
  display: flex;
}

.card-footer {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0.5em;
  height: 56px;
}

.card-button {
  border: none;
  border-radius: 16px;
  color: white;
  background-color: #314D69;
  cursor: pointer;
  padding: 0.75rem 1.75rem;
}

.card-image img{
  max-width: 100%;
  height: auto;
  margin-bottom: 0.75rem;
  border-radius: 8px;
}