.step-navigation-container {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-navigation-container button { 
  background-color: #314D69;
  color: white;
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 0.75rem 1.75rem;
  border: none;
  border-radius: 16px;
  cursor: pointer;
}

.next-button {
  background-color: #314D69;
  color: white;
  margin: 1em 0.5em 2em 0.5em;
  padding: 0.75rem 1.75rem;
  border: none;
  border-radius: 16px;
  cursor: pointer;
}

.secondary-button {
  background-color: transparent;
  border: 1px solid #314D69;
  color: #314D69;
  margin: 1em 0.5em 2em 0.5em;
  padding: 0.75rem 1.75rem;
  border-radius: 16px;
  cursor: pointer;
}

.next-button:disabled {
  background-color: gray;
}

.prev-button {
  border: 1px solid #e3e3e3;
  background: rgba(0, 0, 0, 0);
  margin-right: 1em;
}
