.step-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.large-p {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 2em;
    text-align: center;
    color: #173655;
}

.medium-p {
    font-size: 1.3em;
    margin-top: 1em;
    margin-bottom: 2em;
    text-align: center;
    color: #173655;
}

.standard-p {
    font-size: 1em;
    text-align: center;
    color: #173655;
}

hr {
    border: 0;
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
}

@media only screen and (min-device-width: 300px) 
                   and (max-device-width: 640px) 
                   and (orientation: portrait) {

    .large-p{
        font-size: 1.2em;
        margin-top: 1em;
        margin-bottom: 2em;
    }

    .menu-list li a {
        font-size: 0.8em;
    }
}